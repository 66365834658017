import React from "react";
// import useLanguaje from "../../Hooks/useLanguaje";



export default function Apps({ apps }) {
  
    // const { text } = useLanguaje();

    return (
      <div>Hola mundo</div>
    );
};